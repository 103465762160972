import axios from "axios"

const ENDPOINT = "company_billing/";

const API_URL = `${process.env.VUE_APP_FUSION_API_URL}/`;
const API_KEY = process.env.VUE_APP_FUSION_API_KEY;
const BETA_API_URL = process.env.VUE_APP_BETA_API_URL;
const warehouseAPI = process.env.VUE_APP_WAREHOUSE_API_URL;

const AUTH_TOKEN = process.env.VUE_APP_WAREHOUSE_API_AUTH_TOKEN

// const BETA_API_URL = "https://api-staging.3p-logistics.co.uk"
// let warehouseAPI = `http://node_backend.test/api/v1/billing/`
// const API_URL = "http://3plapi.test/"
// const API_KEY = "8b74723d-98fc-4b16-8d2e-27e123f097ff"

export default {
  async getAdhocCharges(companyId, dateShippedFrom, dateShippedTo, prepId = false) {
    if (!dateShippedFrom) throw Error('Please select a date shipped from.');
    if (!dateShippedTo) throw Error('Please select a date shipped to.');
    let url = `${API_URL}${ENDPOINT}?API-KEY=${API_KEY}&company_id=${companyId}&dateShippedFrom=${dateShippedFrom}&dateShippedTo=${dateShippedTo}`;
    if(prepId){
      url +=`&prep_id=${prepId}`
    }
    let res = await axios.get(url);
    return res.data.data;
  },
  async getChargeCodes(companyId) {
    let params = {
        "API-KEY": API_KEY,
    }
    if(companyId){
      params.companyId = companyId
    }

    let url = `${API_URL}${ENDPOINT}charge_codes_by_company`;
    let res = await axios.get(url, {params: params});
    return res.data.data;
  },

  async createCompanyCharge(reqBody) {
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    let res = await axios.post(`${warehouseAPI}/billing/adhoc-charge`, reqBody);
    return res.data.data;
  },
  async updateCharge(json) {
    var myHeaders = new Headers();
    let urlencoded = new URLSearchParams();
    for (const key in json) {
      if (json.hasOwnProperty(key)) {
        const element = json[key];
        urlencoded.append(key, element)
      }
    }
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    urlencoded.append('API-KEY', API_KEY);
    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    return fetch(`${API_URL}${ENDPOINT}`, requestOptions)
      .then(response => response.json())
      .then(result => this.result = result)
      .catch(err => err.json())
  },
  async deleteCharge(formData) {
    var myHeaders = new Headers();
    let formArray = formData;
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: formArray,
      redirect: 'follow'
    };
    let result = await fetch(`${API_URL}${ENDPOINT}`, requestOptions)
      .then(response => response.json())
      .then(result => this.result = result)
    return result;
  },
  async bulkUploadCharges(reqBody) {
    let res = await axios.post(`${warehouseAPI}/billing/bulk-adhoc-charge`, reqBody);
    return res.data.data;
  },
  async createRecurringCharge(charge) {
    let formData = new FormData;
    formData.append('API-KEY', API_KEY);
    for (const key in charge) {
      if (charge.hasOwnProperty(key)) {
        const value = charge[key];
        formData.append(key, value)
      }
    }
    let res = await axios.post(`${API_URL}${ENDPOINT}recurring_charge`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return res.data;
  },
  async getRecurringCharges(companyId) {
    if (!companyId) throw Error('Please select a Company to view recurring charges.');
    let url = `${API_URL}${ENDPOINT}recurring_charge?API-KEY=${API_KEY}&company_id=${companyId}`;
    let res = await axios.get(url);
    return res.data.data;
  },
  async updateRecurringCharge(charge) {
    let params = new URLSearchParams();
    params.set('API-KEY', API_KEY);
    for (const key in charge) {
      if (charge.hasOwnProperty(key)) {
        const value = charge[key];
        if (value) {
          params.set(key, value)
        }
      }
    }
    let res = await axios.put(`${API_URL}${ENDPOINT}recurring_charge`, params);
    return res.data.data;
  },
  async deleteRecurringCharge(charge) {
    let params = new URLSearchParams;
    params.append('API-KEY', API_KEY);
    params.append('id', charge.id)
    let res = await axios.delete(`${API_URL}${ENDPOINT}recurring_charge`, {data:params});
    return res.data;
  },
  async insertAdhocToCommitted(reqBody) {
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    let res = await axios.post(`${warehouseAPI}/billing/adhoc-charge-to-billing-committed`, reqBody);
    return res.data.data;
  },
  async bulkInsertAdhocToCommitted(reqBody) {
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    let res = await axios.post(`${warehouseAPI}/billing/bulk-adhoc-charge-to-billing-committed`, reqBody);
    return res.data.data;
  },
  async searchAdhocCharges(params){
    let url = `${BETA_API_URL}/company_billing/adhoc-search`;
    params['API-KEY'] = API_KEY
    let res = await axios.get(url,  {params: params});
    return res.data.data;
  },
}