import axios from "axios"

const SORTED_BILLING_UPLOAD = process.env.VUE_APP_FUSION_API_URL;
const API_KEY = process.env.VUE_APP_FUSION_API_KEY;

// const SORTED_BILLING_UPLOAD = "http://3plapi.test"
// const API_KEY = "8b74723d-98fc-4b16-8d2e-27e123f097ff"

const warehouseAPI = process.env.VUE_APP_WAREHOUSE_API_URL;
const AUTH_TOKEN = process.env.VUE_APP_WAREHOUSE_API_AUTH_TOKEN;
// let warehouseAPI = `http://node_backend.test/api/v1`

export default {
  async sortedBillingUpload(formData) {
    // Default options are marked with *
    const response = await fetch(`${SORTED_BILLING_UPLOAD}/sorted_billing`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        "API-KEY": API_KEY
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: formData // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  },

  async getSortedBillingData(weekCommencing) {
    if (!weekCommencing) throw Error ('Please select a Week Commencing to view Data for all Companies.'); 
    let url = `${SORTED_BILLING_UPLOAD}/sorted_billing?API-KEY=${API_KEY}&weekCommencing=${weekCommencing}`;
    let res = await axios.get(`${url}`);    
    return res.data.data;
  },

  async getSortedWeeksCommencing() {
    let url = `${SORTED_BILLING_UPLOAD}/sorted_billing/sorted_billing_week_commencing?API-KEY=${API_KEY}`;
    let res = await axios.get(`${url}`);    
    return res.data.data;
  },

  async querySortedBillingData(query) {        
    if (!query.week_commencing) {
      if (!query.dateShippedFrom) throw Error ('Please select a date shipped from.'); 
      if (!query.dateShippedTo) throw Error ('Please select a date shipped to.'); 
    }
    let queryString = '';
    for (const key in query) {
      if (query.hasOwnProperty(key)) {
        const element = query[key];
        if (element != null) {
          if (key === 'companyId') continue;
          queryString += `&${key}=${element}`
        }
      }
    }     
    let url = `${SORTED_BILLING_UPLOAD}/sorted_billing?API-KEY=${API_KEY}${queryString}`;
    let res = await axios.get(`${url}`)    
    if (res.data.data) {
      return res.data.data;
    }
  },

  async queryWeekSortedBillingData(query) {    
    if (!query.weekCommencing) throw Error ('Please select a Week Commencing to check for duplicates.'); 
    let queryString = '';
    for (const key in query) {
      if (query.hasOwnProperty(key)) {
        const element = query[key];
        if (element != null) {
          queryString += `&${key}=${element}`
        }
      }
    }    
    let url = `${SORTED_BILLING_UPLOAD}/sorted_billing?API-KEY=${API_KEY}${queryString}`;
    let res = await axios.get(`${url}`)    
    if (res.data.data) {
      return res.data.data;
    }
  },

  async getDuplicatedSortedData(query) {
    if (!query.weekCommencing) throw Error ('Please select a Week Commencing to check for duplicates.'); 
    let url = `${SORTED_BILLING_UPLOAD}/company_billing/duplicated_sorted_data?API-KEY=${API_KEY}`;
    if (query.weekCommencing) {
      url += `&weekCommencing=${query.weekCommencing}`;
    }
    let res = await axios.get(`${url}`)        
    if (res.data.data) {
      return res.data.data;
    }
  },

  async deleteSortedItems(items) {    
    var myHeaders = new Headers();    
    let urlencoded = new URLSearchParams();
    urlencoded.append("API-KEY", API_KEY);
    urlencoded.append("items", JSON.stringify(items));
    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    let result = await fetch(`${SORTED_BILLING_UPLOAD}/company_billing/remove_sorted_items`, requestOptions)
      .then(response => response.json())
      .then(result => this.result = result)
    return result;
  },

  async deleteSortedByID(id) {    
    var myHeaders = new Headers();    
    let urlencoded = new URLSearchParams();
    urlencoded.append("API-KEY", API_KEY);
    urlencoded.append("id", id);
    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    let result = await fetch(`${SORTED_BILLING_UPLOAD}/company_billing/remove_sorted`, requestOptions)
      .then(response => response.json())
      .then(result => this.result = result)
    return result;
  },
  async deleteAllSorted(week) {    
    var myHeaders = new Headers();    
    let urlencoded = new URLSearchParams();
    urlencoded.append("API-KEY", API_KEY);
    urlencoded.append("week_commencing", week);
    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    let result = await fetch(`${SORTED_BILLING_UPLOAD}/company_billing/remove_sorted_by_week`, requestOptions)
      .then(response => response.json())
      .then(result => this.result = result)
    return result;
  },

  async getSortedTotalCost() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic d2FyZWhvdXNlQWRtaW46M1BMJldIRChBUEkp");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    let result = fetch("https://reports.3p-logistics.co.uk/api/v1/warehouse/systems/sorted-billing-errors", requestOptions)
    .then(response => {return response.json()})
    .catch(error => {return error});
    return result;
  },
  async getCompanyAverages(companyId) {         
    let url = `${SORTED_BILLING_UPLOAD}/company_billing/invoice_averages?API-KEY=${API_KEY}&companyId=${companyId}`;
    let res = await axios.get(`${url}`)    
    if (res.data.data) {
      return res.data.data;
    }
  },
  async getGlobalCompanyAverages() {         
    let url = `${SORTED_BILLING_UPLOAD}/company_billing/invoice_global_averages?API-KEY=${API_KEY}`;
    let res = await axios.get(`${url}`)    
    if (res.data.data) {
      return res.data.data;
    }
  },
  async getSortedBillingImportErrors(date){
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    let dateParam = date ? date : ''
    let url = `${warehouseAPI}/billing/sorted-billing-import-errors?date=${dateParam}&timestamp=${new Date().getTime()}`;
    let res = await axios.get(url);
    return res.data.data;
  },
  async deleteSortedBillingError(reqBody) {
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    let res = await axios.delete(`${warehouseAPI}/billing/sorted-billing-import-errors`, {data:reqBody});
    return res.data.data;
  },

}