<template>
  <v-col :cols="colWidth ? colWidth : 6">
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="value"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="datePickerRange"
          label="Date Picker"
          prepend-inner-icon="event"
          readonly
          dense
          outlined
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker v-model="datePicker" no-title scrollable range>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
        <v-btn text color="primary" @click="saveDates(datePicker)">OK</v-btn>
      </v-date-picker>
    </v-menu>
  </v-col>
</template>

<script>
export default {
  props: [
    "value",
    "colWidth"
  ],
  data() {
    return {
      datePicker: [],
      menu: false
    }
  },
  methods: {
    saveDates (datePicker) {
      this.$refs.menu.save(datePicker)
      this.$emit('clicked', datePicker)
    }
  },
  computed: {
    datePickerRange () {
      return this.value.join(' ~ ')
    },
  }
}
</script>

<style>
.theme--light.v-date-picker-table th:not([align]) {
  text-align: center;
}
</style>