import axios from "axios"

const commitDetailURL = `${process.env.VUE_APP_BETA_API_URL}/company_billing/commit_billing_data`;
const commitXeroURL = `${process.env.VUE_APP_BETA_API_URL}/company_billing/commit_xero_billing_data`;
const API_KEY = process.env.VUE_APP_FUSION_API_KEY;

// const commitDetailURL = `http://3plapi.test/company_billing/commit_billing_data`;
// const commitXeroURL = `http://3plapi.test/company_billing/commit_xero_billing_data`;
// const API_KEY = '8b74723d-98fc-4b16-8d2e-27e123f097ff';


export default {
  async commitDetailedData(jsonData, company_id, invoice_number, prep_id = false, charge_date = false) {
    let formData = new FormData;
    formData.append('API-KEY', API_KEY);
    formData.append('company_id', company_id);
    formData.append('invoice_number', invoice_number);
    formData.append('data', JSON.stringify(jsonData));
    if(prep_id){
      formData.append('prep_id', prep_id);
    }
    if(charge_date){
      formData.append('charge_date', charge_date);
    }
    let res = await axios.post(commitDetailURL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });    
    return res;
  },
  async commitXeroData(jsonData, company_id, prep_id = false) {
    let formData = new FormData;
    formData.append('API-KEY', API_KEY);
    formData.append('company_id', company_id);
    formData.append('data', JSON.stringify(jsonData));
    if(prep_id){
      formData.append('prep_id', prep_id);
    }
    let res = await axios.post(commitXeroURL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });    
    return res;
  },
}

