<template>
  <div class="createInvoice">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">{{ title }}</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading">
        <v-form v-model="valid" class="ma-2" @submit.prevent>
          <v-container>
            <v-row>
              <v-col cols="4">
                <v-select
                  v-model="companySelected"
                  @change="getCompanyData"
                  :items="companies"
                  item-text="name"
                  item-value="id"
                  label="Select Company"
                  prepend-inner-icon="business"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
              <v-col cols="4"    v-if="isPrepCompany">
                <v-select
               
                  menu-props="auto"
                  v-model="selectedPrepId"
                  :items="prepCompaniesList"
                  item-text="name"
                  item-value="id"
                  label="Select Prep Company"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
              <date-picker
                @clicked="setDateRange"
                v-model="datesPicked"
                :colWidth="4"
              ></date-picker>
              <v-col>
                <v-btn
                  class="ml-1 mr-1"
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  @click="createCompanyInvoice()"
                  >View Billing Data</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
      <v-card v-if="missingMargin.length > 0" class="mb-4">
        <v-container>
          <h2>The following margins are missing</h2>
          <p v-for="margin in missingMargin" :key="margin.id">{{ margin }}</p>
        </v-container>
      </v-card>
      <div
        class="v-card v-card--outlined v-sheet theme--light mb-4"
        v-if="processedData.length > 0"
      >
        <v-form v-model="valid" class="ma-2" @submit.prevent>
          <v-container>
            <v-row class="control columns">
              <div class="column is-one-fifth">
                <label for="setInvoiceDate">Set Invoice Date:</label>
                <input
                  type="date"
                  class="input"
                  name="setInvoiceDate"
                  id="setInvoiceDate"
                  v-model="query.setInvoiceDate"
                  v-on:change="enableFileExport()"
                />
                <div class="errorMessage">
                  <p>Set Invoice Date to Export File</p>
                </div>
              </div>
            </v-row>
          </v-container>
        </v-form>
      </div>

      <div class="snapBillingTable" v-if="processedData.length > 0">
        <v-btn
          @click="insertBillingData()"
          type="button"
          :disabled="invoiceDateSet || loading"
          class="mb-2 v-btn v-btn--depressed theme--light v-size--small primary"
          >Submit Billing Data</v-btn
        >
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              class="mb-2 ml-2"
              v-on="on"
              :disabled="disableAddCharge"
              >Add Charge</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Add Charge to Invoice</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <label for="client">Client:</label>
                <input
                  disabled
                  class="input column is-3"
                  placeholder="Selected Client"
                  type="text"
                  name="client"
                  id="client"
                  v-model="adhocCharge.client"
                />
                <div class="errorMessage" v-if="errors.client">
                  <p>{{ errors.client }}</p>
                </div>
                <label for="chargeDate">Date to Apply:</label>
                <input
                  type="date"
                  class="input column"
                  name="chargeDate"
                  id="chargeDate"
                  v-model="adhocCharge.chargeDate"
                />
                <div class="errorMessage" v-if="errors.chargeDate">
                  <p>{{ errors.chargeDate }}</p>
                </div>
                <label for="chargeType">Charge Type:</label>
                <input
                  required
                  placeholder="Type of Charge being applied"
                  type="text"
                  class="input"
                  name="chargeType"
                  id="chargeType"
                  v-model="adhocCharge.chargeType"
                />
                <div class="errorMessage" v-if="errors.chargeType">
                  <p>{{ errors.chargeType }}</p>
                </div>
                <label for="reference">Reference:</label>
                <input
                  required
                  placeholder="Reference Number"
                  type="text"
                  class="input"
                  name="reference"
                  id="reference"
                  v-model="adhocCharge.reference"
                />
                <div class="errorMessage" v-if="errors.reference">
                  <p>{{ errors.reference }}</p>
                </div>
                <label for="clientReference">Client Reference:</label>
                <input
                  required
                  placeholder="Reference which client will identify charge."
                  type="text"
                  class="input"
                  name="clientReference"
                  id="clientReference"
                  v-model="adhocCharge.clientReference"
                />
                <div class="errorMessage" v-if="errors.clientReference">
                  <p>{{ errors.clientReference }}</p>
                </div>
                <label for="UOM">Unit of Measurement:</label>
                <input
                  placeholder="How is the charge measured."
                  type="text"
                  class="input"
                  name="UOM"
                  id="UOM"
                  v-model="adhocCharge.UOM"
                />
                <label for="billableUnits">Billable Units:</label>
                <input
                  placeholder="Amount of units for Charge"
                  type="text"
                  class="input"
                  name="billableUnits"
                  id="billableUnits"
                  v-model="adhocCharge.billableUnits"
                />
                <label for="tariff">Tariff:</label>
                <input
                  placeholder="The customers tarriff for charge."
                  type="text"
                  class="input"
                  name="tariff"
                  id="tariff"
                  v-model="adhocCharge.tariff"
                />
                <label for="totalCharge">Pick Cost:</label>
                <input
                  placeholder="£"
                  type="number"
                  class="input column is-3"
                  step="0.01"
                  name="totalCharge"
                  id="totalCharge"
                  v-model.number="adhocCharge.totalCharge"
                />
                <div class="errorMessage" v-if="errors.totalCharge">
                  <p>{{ errors.totalCharge }}</p>
                </div>
                <label for="charge_code">Charge Code:</label>
                <input
                  list="chargeCodes"
                  class="input column"
                  name="charge_code"
                  id="charge_code"
                  v-model="selectedChargeCode"
                  v-on:change="setChargeCode()"
                />
                <datalist id="chargeCodes">
                  <option
                    v-for="chargeCode in chargeCodes"
                    v-bind:value="chargeCode.name"
                    :key="chargeCode.id"
                  ></option>
                </datalist>
                <div class="errorMessage" v-if="errors.charge_code">
                  <p>{{ errors.charge_code }}</p>
                </div>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div>
          <button
            @click="exportToExcel('xlsx')"
            type="button"
            class="
              mb-2
              v-btn v-btn--depressed
              theme--light
              v-size--small
              primary
            "
          >
            Excel
          </button>
          <button
            @click="exportToExcel('csv')"
            type="button"
            class="
              ml-2
              mb-2
              v-btn v-btn--depressed
              theme--light
              v-size--small
              primary
            "
          >
            CSV
          </button>
        </div>
        <v-card>
          <v-card-title>
            Company Billing Data
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="snapHeaders"
            :items="processedData"
            :items-per-page="5"
            :search="search"
            id="data-table"
          >
            <template v-slot:item.chargeDate="{ item }">
              {{ item.chargeDate.split(" ")[0] }}
            </template>
            <template v-slot:item.totalCharge="{ item }">
              <v-chip dark>{{ renderCurrency(item.totalCharge) }}</v-chip>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <v-snackbar v-model="snackbar" :timeout="5000">
        {{ text }}
        <v-btn color="blue" text @click="snackbar = false"></v-btn>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import CompaniesUtil from "@/services/CompaniesUtil.js";
import SortedUpload from "@/services/SortedUpload.js";
import BillingSnap from "@/services/BillingSnap.js";
import Margins from "@/services/Margins.js";
import AdhocCharges from "@/services/AdhocCharges.js";
import CommitData from "@/services/CommitData.js";
import * as XLSX from 'xlsx'

import DatePicker from "../components/forms/DatePicker";

export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      title: "Submit Invoice Data For Prep Companies",
      companySelected: {},
      datesPicked: [],
      // Snackbar
      snackbar: false,
      text: "",
      response: null,
      // Dialog
      dialog: false,
      disableAddCharge: false,
      adhocCharge: {
        company_id: null,
        prep_id: null,
        client: null,
        chargeDate: null,
        chargeType: null,
        reference: null,
        clientReference: null,
        UOM: null,
        billableUnits: null,
        tariff: null,
        totalCharge: 0,
        chargeCode: null,
      },
      errors: {
        shipment_id: null,
        pick_cost: null,
        charge_code: null,
        date_applied: null,
      },
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => typeof value == Number || "Value must be a number",
      },
      // Upload
      search: "",
      loading: false,
      data: [],
      // Company
      selectedCompany: [],
      companyName: null,
      valid: false,
      // Query
      query: {
        companyCode: null,
        companyId: null,
        dateShippedFrom: null,
        dateShippedTo: null,
        setInvoiceDate: null,
      },
      queryRun: false,
      // Billing Data
      snapBillingData: [],
      sortedBillingData: [],
      adhocCharges: [],
      processedData: [],
      recurringCharges: [],
      // Company Margins
      companyMargins: [],
      // Snap Table
      snapHeaders: [
        { text: "Client", value: "client" },
        { text: "Charge Date", value: "chargeDate" },
        { text: "Charge Type", value: "chargeType" },
        { text: "Reference", value: "reference" },
        { text: "Client Reference", value: "clientReference" },
        { text: "UOM", value: "UOM" },
        { text: "Billable Units", value: "billableUnits" },
        { text: "Tariff", value: "tariff" },
        { text: "Total Charge", value: "totalCharge" },
        { text: "Charge Code", value: "chargeCode" },
      ],
      // Charge Codes
      chargeCodes: [],
      selectedChargeCode: null,
      chargeCode: null,
      // Xero Export
      xeroExportData: [],
      // Combined Charge Codes
      combinedChargeCodes: [],
      // Check Invoice Date Set
      invoiceDateSet: true,
      // Commit Response
      commitDetailedResponse: null,
      commitXeroResponse: null,
      // Margins Check
      marginsSet: false,
      marginErrors: null,
      totalAmountCombined: 0,
      totalAverageCharge: 0,
      missingMargin: [],
      averageCharges: [],
      companies: [],

      isPrepCompany: true,
      prepCompaniesList: [],
      selectedPrepId: null,
    };
  },
  created() {
    this.loadInitialData();
  },
  methods: {
    setDateRange(dates) {
      this.datesPicked = dates;
    },
    setCompanyQuery() {
      this.query.companyCode = this.selectedCompany.code;
      this.query.companyId = this.selectedCompany.id;
      this.query.name = this.selectedCompany.name;
    },
    setDateRangeQuery() {
      this.query.dateShippedFrom = this.datesPicked[0];
      this.query.dateShippedTo = this.datesPicked[1];
    },
    exportToExcel(type) {
      var wb2 = XLSX.utils.book_new();
      var ws2 = XLSX.utils.json_to_sheet(this.processedData);
      XLSX.utils.book_append_sheet(wb2, ws2, "Combined Detailed Data");
      XLSX.writeFile(
        wb2,
        "combinedDetailedData" +
          this.query.companyCode +
          "_" +
          this.query.dateShippedFrom +
          "_" +
          this.query.dateShippedTo +
          "." +
          type
      );
    },
    checkAverageStatus(value) {
      if (value < -20) {
        return "font-weight-bold red--text text--lighten-1";
      } else if (value > 20) {
        return "font-weight-bold green--text text--lighten-1";
      } else {
        return "font-weight-bold";
      }
    },
    // Dialog Methods
    save() {
      this.loading = true;
      this.adhocCharge.prep_id = this.selectedPrepId;
      this.adhocCharge.client = this.query.companyCode;
      this.adhocCharge.company_id = this.query.companyId;
      this.adhocCharge.shipment_id =
      this.query.companyCode + this.adhocCharge.shipment_id;
      let reqBody = this.adhocCharge
      reqBody.onlyAddChargeToCommitted = this.onlyAddChargeToCommitted
      reqBody.includeChargeToCommitted = this.includeChargeToCommitted
      reqBody.invoiceNumber = this.invoiceNumber
      
      AdhocCharges.createCompanyCharge(reqBody)
        .then(
          ((response) => {
            this.$set(this, "response", response);
            this.createCompanyInvoice();
            this.loading = false;
            this.snackbar = true;
            this.text = `Charge ${this.adhocCharge.reference} created for ${this.query.name}`;
            this.adhocCharge = Object.assign({}, this.defaultItem);
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          if (error.response.status === 400) {
            let errorMessageString = "Error: ";
            error.response.data.errorMessage.forEach((element) => {
              errorMessageString += ` ${element}`;
            });
            this.text = errorMessageString;
          } else {
            this.text = `${error.message}`;
          }
          this.loading = false;
          return;
        });
      this.close();
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.addedCharge = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    setChargeCode() {
      let tempChargeCode = this.chargeCodes.find(
        (code) => code.name === this.selectedChargeCode
      );
      this.adhocCharge.chargeCode = tempChargeCode.code;
    },
    // Export Settings
    insertBillingData() {
      this.loading = true;
      this.xeroExportData = [];
      let date = new Date();
      let dueDate = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        15
      ).toLocaleString("en-GB", { hour12: false });
      let invoiceDate = new Date(this.query.setInvoiceDate).toLocaleString(
        "en-GB",
        { hour12: false }
      );
      let todayDate = this.query.setInvoiceDate.split("-");
      // Create Invoice Name
      let invoiceName = `${todayDate[0].slice(0, 4)}${todayDate[1]}${
        todayDate[2]
      }`;

      this.combinedChargeCodes.forEach((element) => {
        let tempArray = {
          ContactName: this.query.name,
          EmailAddress: null,
          POAddressLine1: null,
          POAddressLine2: null,
          POAddressLine3: null,
          POAddressLine4: null,
          POCity: null,
          PORegion: null,
          POPostalCode: null,
          POCountry: null,
          InvoiceNumber: invoiceName,
          Reference: null,
          InvoiceDate: invoiceDate,
          DueDate: dueDate,
          Total: null,
          InventoryItemCode: null,
          Description: element.name,
          Quantity: 1,
          UnitAmount: parseFloat(element.amount),
          Discount: null,
          AccountCode: element.code,
          TaxType: "20% (VAT on income)",
          TaxAmount: null,
          TrackingName1: "Department",
          TrackingOption1: element.TrackingOption1,
          TrackingName2: null,
          TrackingOption2: null,
          Currency: null,
          BrandingTheme: null,
        };
        this.xeroExportData.push(tempArray);
      });
      CommitData.commitXeroData(
        this.xeroExportData,
        this.query.companyId,
        this.selectedPrepId
      )
        .then(
          ((commitXeroResponse) => {
            this.$set(this, "commitXeroResponse", commitXeroResponse);
            this.snackbar = true;
            this.text = `Successfully committed Xero data for ${this.query.name}.`;
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.response.data.errorMessage[0]}`;
          this.loading = false;
          return;
        });
      // Commit Sorted / Snap Data
      CommitData.commitDetailedData(
        this.processedData,
        this.query.companyId,
        invoiceName,
        this.selectedPrepId
      )
        .then(
          ((commitDetailedResponse) => {
            this.$set(this, "commitDetailedResponse", commitDetailedResponse);
            this.text = `Successfully committed Detailed Billing data for ${this.query.name}.`;
            this.snackbar = true;
            this.loading = false;
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.response.data.errorMessage[0]}`;
          this.loading = false;
          return;
        });
    },
    renderCurrency(value) {
      if (value === null) {
        value = 0.0;
      }
      return `£${parseFloat(value).toFixed(2)}`;
    },
    setCompanyCode() {
      this.getCompanyData(this.companySelected.id);
      this.query.companyCode = this.companySelected.code;
      this.query.companyId = this.companySelected.id;
      this.adhocCharge.client = this.companySelected.code;
    },
    enableFileExport() {
      if (this.query.setInvoiceDate) {
        this.invoiceDateSet = false;
      }
    },

    // Charge Codes
    async getChargeCodes(companyId) {
      // Use the BillingSnap to call the getBillingData() method
      return AdhocCharges.getChargeCodes(companyId)
        .then(
          ((chargeCodes) => {
            this.$set(this, "chargeCodes", chargeCodes);
            return true;
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return false;
        });
    },
    async getCompanyData(companyID) {
      // Use the BillingSnap to call the getBillingData() method
      this.getChargeCodes(companyID);

      CompaniesUtil.getCompany(companyID)
        .then(
          ((selectedCompany) => {
            this.$set(this, "selectedCompany", selectedCompany);
            this.loading = true;
            BillingSnap.getPrepCompanies(selectedCompany.code)
              .then((response) => {
                this.loading = false;
                this.prepCompaniesList = response;
              })
              .catch((error) => {
                this.snackbar = true;
                this.text = `${error.message}`;
                this.loading = false;
              });
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return;
        });
    },

    async createCompanyInvoice() {
      this.processedData = [];
      this.loading = true;
      if (!this.selectedPrepId) {
        return;
      }

      this.setCompanyQuery();
      this.setDateRangeQuery();

      Margins.getCompanyServiceMargins(
        this.query.companyId,
        this.selectedPrepId
      )
        .then(
          ((companyMargins) => {
            if (companyMargins.length === 0) {
              throw Error(
                "Company Margins have not been set. Please set them before continuing."
              );
            } else {
              this.$set(this, "companyMargins", companyMargins);
              this.marginsSet = true;
              this.applyCompanyMargins();
            }
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return;
        });
    },
    applyCompanyMargins() {
      let tempSorted = [];
      let tempAdHoc = [];
      this.missingMargin = [];
      SortedUpload.querySortedBillingData(this.query)
        .then(
          ((sortedBillingData) => {
            this.marginErrors = false;
            if (sortedBillingData.length > 0) {
              this.$set(this, "sortedBillingData", sortedBillingData);
              // filter results only for this prep company
              let prepRef = this.query.companyCode + this.selectedPrepId + "-";
              sortedBillingData.forEach((element) => {
                if (element.state !== "Unallocated") {
                  if (
                    !element.orderReference ||
                    !element.orderReference.startsWith(prepRef)
                  ) {
                    return;
                  }
                  let cost = parseFloat(element.totalCost);
                  let margin = this.companyMargins.find(
                    (margin) =>
                      margin.carrier_service_id.toLowerCase().trim() ==
                      element.service.toLowerCase().trim()
                  );
                  if (!margin) {
                    this.marginErrors = true;
                    this.missingMargin.push(
                      element.service
                        ? `${element.carrier_name} - ${element.service}`
                        : "Unallocated"
                    );
                    return Error(
                      `${element.service} still needs to be set for ${this.companySelected.name}. Please go to Company Margins to complete company margins.`
                    );
                  }
                  let marginAmount = parseFloat(margin.margin) / 100;
                  let totalCost = cost + cost * marginAmount;
                  let fixedRate = null;
                  let costForPackages = null;
                  if (margin.fixedRateActive === "1") {
                    fixedRate = parseFloat(margin.fixedRate);
                    costForPackages = parseFloat(
                      fixedRate * element.numberOfPackages
                    );
                    if (costForPackages > totalCost)
                      totalCost = costForPackages;
                  }
                  let sortedChargeCode = 4010;
                  if (element.carrier_name == "Royal Mail") {
                    sortedChargeCode = 4018;
                  }
                  // change charge codes for royal mail and hermes
                  if(element.carrier_name == 'Hermes' || element.carrier_name == 'Hermes International' ||  element.carrier_name == 'HERMES_ROTW' || element.carrier_name == 'HMS'){
                    sortedChargeCode = 4019
                  }

                  let tempArray = {
                    client: element.company_code,
                    chargeDate: element.dateShipped,
                    chargeType: element.service,
                    reference: element.orderReference,
                    clientReference: element.customerName,
                    UOM: 1,
                    billableUnits: element.numberOfPackages,
                    tariff:
                      margin.fixedRateActive === "1" &&
                      fixedRate > 0 &&
                      costForPackages != null
                        ? fixedRate
                        : null,
                    totalCharge: totalCost.toFixed(2),
                    chargeCode: sortedChargeCode,
                  };
                  tempSorted.push(tempArray);
                }
              });
              this.missingMargin = [...new Set(this.missingMargin)];
              this.text = `Successfully Loaded Sorted data for ${prepRef}`;
              this.combineDataSets(tempSorted);
            }
          }).bind(this)
        )
        .catch((error) => {
          if (error.message === "Request failed with status code 401") {
            error.message =
              "No Sorted data has been found for the selected period or there is no data for this company. Please check the data has been uploaded.";
          }
          this.combinedChargeCodes = [];
          this.marginErrors = true;
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return;
        });
      BillingSnap.getGenericBilling(
        this.query.companyId,
        this.query.dateShippedFrom,
        this.query.dateShippedTo,
        this.selectedPrepId
      )
        .then(
          ((snapBillingData) => {
            if (this.marginErrors)
              throw Error(
                `Not all the Margins have been set for this ${this.companySelected.name}.`
              );
            this.$set(this, "snapBillingData", snapBillingData);
            this.loading = false;
            this.text = `Successfully Loaded Data between ${this.query.dateShippedFrom} and ${this.query.dateShippedTo}`;
            this.snackbar = true;
            this.combineDataSets(this.snapBillingData);
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return;
        });
      AdhocCharges.getAdhocCharges(
        this.query.companyId,
        this.query.dateShippedFrom,
        this.query.dateShippedTo,
        this.selectedPrepId
      )
        .then(
          ((adhocCharges) => {
            if (this.marginErrors) return false;
            this.$set(this, "adhocCharges", adhocCharges);
            if (adhocCharges.length > 0) {
              adhocCharges.forEach((element) => {
                let tempArray = {
                  client: element.client,
                  chargeDate: element.charge_date,
                  chargeType: element.charge_type,
                  reference: element.reference,
                  clientReference: element.client_reference,
                  UOM: element.UOM,
                  billableUnits: element.billable_units,
                  tariff: element.tariff,
                  totalCharge: parseFloat(element.total_charge),
                  chargeCode: element.charge_code,
                };
                tempAdHoc.push(tempArray);
                this.queryRun = true;
              });
              this.combineDataSets(tempAdHoc);
            }
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return;
        });
    },
    combineDataSets(dataSet) {
      dataSet.forEach((element) => {
        this.processedData.push(element);
      });
    },
    percentageDiff(averageCharge) {
      let currentCharge = this.combinedChargeCodes.find(
        (charge) => charge.code === averageCharge.account_code
      );
      if (currentCharge) {
        const A = parseInt(currentCharge.amount);
        const B = parseInt(averageCharge.average);
        const percent = this.getPercentageChange(B, A);

        return percent;
      } else {
        return 0;
      }
    },
    getPercentageChange(oldNumber, newNumber) {
      var decreaseValue = oldNumber - newNumber;
      return ((decreaseValue / oldNumber) * -100).toFixed(0);
    },
    async loadInitialData() {
      let companyArray = await this.$root.sharedData.getCompanies();
      if (companyArray) {
        let filteredPrepCompanies = [];
        for (let i = 0; i < companyArray.length; i++) {
          let comp = companyArray[i];
          if (comp.name === "PREPMATE" || comp.code === "V08") {
            filteredPrepCompanies.push(comp);
          }
        }
        this.companies = filteredPrepCompanies;
      }
    },
  },
  computed: {
    computeChargeCode() {
      if (this.processedData.length > 0) {
        return this.processedData;
      } else {
        return null;
      }
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    computeChargeCode: {
      handler: function (newVal) {
        if (!newVal) return;
        let totalAmount = 0;
        this.combinedChargeCodes = [];
        this.chargeCodes.forEach((element) => {
          let tempCode = element.code;
          let codeName = this.chargeCodes.find(
            (data) => data.code === tempCode
          );
          let temp = newVal.filter((data) => data.chargeCode == tempCode);
          if (temp.length > 0) {
            let tempAmount = temp.map((charge) =>
              parseFloat(charge.totalCharge)
            );
            let reduceAmount = tempAmount.reduce(function (acc, amount) {
              return acc + amount;
            });
            totalAmount += reduceAmount;
            this.combinedChargeCodes.push({
              code: tempCode,
              name: codeName.name,
              TrackingOption1: codeName.TrackingOption1,
              amount: reduceAmount.toFixed(2),
            });
          }
        });
        this.totalAmountCombined = totalAmount;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.errorMessage {
  font-size: smaller;
  margin-top: 12px;
  p {
    margin-bottom: 0px;
  }
}
.summary {
  display: flex;
  .box-div {
    padding: 1em;
  }
}
</style>